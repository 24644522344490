
/* responsive - style Sheet */

@media (min-width: 576px){

    .modal-dialog {
        max-width: 500px;
        margin: 3.75rem auto;
    } 
    .modal-dialog.modal-xl{
        max-width: 900px;
        margin: 3.75rem auto;
    }
    
    video{
        width: 100% !important;
        height: 250px !important;
       }
     

}



@media (max-width: 575.98px) { 
    .top_wrapper_plan {
        margin-bottom: 2rem;
        background-color: #ffffff36;
        padding-bottom: 1rem;
        padding-top: 2rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        text-align: center;
        border-radius: 1px 34px 0px 49px;
    }
    
    .join_community_wrapper {
        margin-top: 0rem !important;
    }
    
    .join_community_wrapper h1 {
        font-size: 1.5rem !important;
    }
    
    .community_section {
        padding-top: 4rem;
        padding-bottom: 4rem;
        text-align: center;
        min-height: 293px;
    }

    .defualt_bg {
        padding-top: 0rem;
        padding-bottom: 3rem;
    }
    
    .page {
        background-color: #ffffff;
        padding-top: 2rem;
        padding-bottom: 2rem;
        border-radius: 0;
        border-top: 2px solid #1e7e34;
    }

    #sitelogo {
        /* max-width: 193px; */
        /* max-width: 168px; */
        max-width: 130px;
    }

    .main_banner_row {
        min-height: 95vh;
        background-size: cover;
        background-position-x: -32rem;
    }

    .pagination {
        justify-content: center!important;
        display: flex;
        flex-flow: wrap;
        margin-left:2px;
        margin-right: 2px;
    }


    .user_profile_ls {
        width: 82px;
        height: 82px;
        border-radius: 73px;
        overflow: hidden;
        margin-left: auto;
        margin-right: auto;
        margin-top: 9px;
        border: 3px solid #989797;
    }

    .user_profile_ls img {
        height: 78px;
        width: 100%;
        -o-object-fit: cover;
        object-fit: cover;
        -o-object-position: center;
        object-position: center;
    }

    .mb-bg-tp{
        padding-left: 5px;
        padding-right: 5px;
    }

    .matched {
        background-color: transparent;
        border: 0px;
        width: 100%;
        font-size: 24px;
        color: #00ff48;
    }

    .not_matched {
        background-color: transparent;
        border: 0px;
        width: 100%;
        font-size: 24px;
        color: #ff0000;
    }

    .col-md-8{
        position: relative;
        width: 100%;
        padding-right: 5px;
        padding-left: 5px;
    }

    .col-md-8 .card-header, .col-sm-12 .card-header {
        padding: .75rem 1.25rem;
        background-color: rgb(34 41 45);
        border-bottom: 0px;
        margin-bottom: 12px;
    }

    .thumb_navigation{
        display: none !important;
    }

    #user-banner{
        min-height: 212px !important;
        background-color: #464646;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        overflow: hidden;
        position: relative;
    }


    .over_flow_wrapper_big .card-title {
        margin-bottom: .75rem;
        font-size: 1rem;
        text-align: left;
    }

    .user_profile_ls {
        width: 55px;
        height: 55px;
        border-radius: 100px;
        overflow: hidden;
        margin-left: auto;
        margin-right: auto;
        margin-top: 9px;
        border: 3px solid #989797;
    }

    .over_flow_wrapper_big .card-body {
        flex: 1 1 auto;
        min-height: 1px;
        padding: 9px;
        text-align: left;
    }

    .m-50-wrapper .btn-group {
        max-width: 200px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 0px !important;
        text-align: center;
        margin-bottom: 15px;
    }

    .m-50-wrapper p{
        font-size: 0.9rem;
    }

    .over_flow_wrapper_big {
        max-height: 3000px;
        overflow: auto;
        overflow-x: hidden;
        padding: 0.2rem;
        text-align: center;
    }

    .col-sm-12{
        position: relative;
        width: 100%;
        padding-right: 10px;
        padding-left: 10px;
    }


    .g-0 .btn-group.mt-4 {
        margin-bottom: 15px;
        margin-top: 4px !important;
    }

    .table-responsive{
        max-width: 550px !important;
        overflow: scroll !important;
    }

    .create-album-w{
        margin-bottom: 20px !important;
    }

    .create-album-wrapper img {
        height: 140px !important;
        width: 100%;
        object-fit: cover;
    }

    .bottom-text-wrapper p {
        color: #cfcfcf;
        font-size: 1.1rem;
    }

    .modal.show {
        display: block !important;
        min-width: 100%;
        overflow-y: auto;
        background-color: #111d;
    }

    .timeline_wrapper{
        max-height: 3000px !important;
        overflow-y: hidden;
    }

    select{
        height: 36px !important;
        border: 1px solid;
        border-radius: 4px;
    }

    input{
        height: 40px !important;
        border-radius: 5px;
    }

    .search-hot-date-wrapper {
        background-color: #22292d;
        margin-bottom: 15px;
        padding: 10px;
    }

    .hot-date-btn {
        margin-bottom: 18px;
    }

    .swinger-search-date-wrapper{
        background-color: #22292d;
        margin-bottom: 15px;
        padding: 10px;
    }

    ._mt_btn_36 {
        margin-top: 15px !important;
    }

    .event-bt-btn {
        margin-bottom: 20px;
    }

    .nav-link {
        display: block;
        padding: 0rem 0rem;
        /* padding: .5rem 1rem; */
        color: #fff !important;
    }

    .mob-nav-notification{
        display: inline-block;
    }
    
    .firend-navigation a{
        font-size: 13px;
    }

    .btn {
        border-radius: 45px;
        padding-left: 0.8rem;
        padding-right: 0.8rem;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        font-size: 0.9rem;
    }
    .m-view-icon {
        /* font-size: 21px;
        color: #fff;
        margin-left: -12px;
        position: absolute;
        margin-top: -30px;
        display: block !important;
        opacity: 1; */
        font-size: 21px;
    color: #fff;
    margin-left: -12px;
    position: absolute;
    margin-top: 10px;
    display: block !important;
    opacity: 1;
    }
    
    .desktop-view{
        display: none !important;
    }

    .m-flex-v{
        display: flex;
        margin-bottom: 14px;
        padding-top: 0px;
    }

    .mb-21 {
        margin-bottom: 2px !important;
    }

    h5>a {
        color: #f2f2f2;
        text-decoration: none;
        text-transform: capitalize;
        font-size: 1rem;
        font-weight: 400;
    }


    .m-view{
        display: inline-block !important;
    }

    .main-comment-wrapper{
        background-color: #212529;
    }

    .comment-user-profile{
        width: 42px;
        height: 42px;
        object-fit: cover;
        object-position: center;
        float: left;
        margin-right: 15px;
        position: relative;
    }

   .btn-group {
        max-width: 200px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 0px !important;
        text-align: left;
        margin-bottom: 15px;
        margin-left: 10px !important;
    }

    .btn-group-responsive {
        margin: 0px auto !important;
    }

    video{
        width: 100% !important;
        height: 150px !important;
       }
     
       .media-cont {
        position: relative;
        top: 10px;
        left: 10px;
    }
    .media-body{
        padding-top: 5px;
        padding-left: 5px;
    }
    .media-body h5{
        font-size: 14px;
      }
    .media-body h6, p{
      font-size: 12px;
    }
    
    }


    /* ipad data */

    @media (max-width: 768.98px) { 
        .user-navbar{
            display: none !important;
        }

        .m-view{
            display: inline-block !important;
        }

        .user_profile {
            margin-bottom: 15px;
        }

        .edit-user-profile{
            margin-top: 6px;
            margin-left: 0px !important;
        }

        .m-screen{
            display: none !important;
        }

        .desktop-view{
            display: none !important;
        }

        .m-flex-v{
            display: flex;
            margin-bottom: 14px;
            padding-top: 0px;
        }
        

        .swinger-mobile-navigation {
            padding: 1.2rem;
            top: 20rem;
            position: absolute;
            z-index: 9;
            background-color: #212529;
            transition: all 0.8s;
            -webkit-transition: all 0.8s;
            display: block ;
        }

        .show_navigation {
            top: 0rem;
        }


        .swinger-mobile-navigation h1 {
            font-size: 1.2rem;
        }

        .swinger-mobile-navigation a {
            color: #fbfbfb;
            text-decoration: none;
            background-color: transparent;
        }

        .swinger-mobile-navigation .card-header {
            padding: .4rem 0.25rem;
            margin-bottom: 0;
            background-color: rgba(0,0,0,.03);
            border-bottom: 1px solid rgba(0,0,0,.125);
            font-size: 0.8rem;
        }

        .swinger-mobile-navigation .card-body {
            flex: 1 1 auto;
            min-height: 1px;
            padding: 0.25rem;
        }

        .moboview-sow {
            position: absolute;
            z-index: 99;
            bottom: 0;
            font-size: 1.5rem;
            color: #fdd835;
        }

        .moboview-sow{
            display: block;
        }


    }




@media (max-width: 991.98px) { 

 }


@media (max-width: 1199.98px) { 

 }


@media (max-width: 1399.98px) { 

 }

